<template>
	<div class="error-index-main">
		<el-empty description="加载失败"></el-empty>
		 <button class="btn-text" @click="refresh">刷新</button>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		onLoad(){
			
		},
		methods:{
			refresh(){
				this.$router.push({path: '/index'});
			}
		}
	}
</script>

<style scoped lang="scss">
	.error-index-main{width: 100%;height: 100%;padding: 20% 0;
		.btn-text{display: block; margin: 0 auto;background: none;font-size: 15px;color: #838383;}
	}
	
</style>